import React, { useEffect, useState, useRef, useContext } from "react";
import CustomTable from "../commons/CustomTable";
import CustomBadge from "../commons/CustomBadge";
import HeaderWithArrow from "../commons/HeaderWithArrow";
import PoNote from "./PoNote";
import { Route, Switch, useHistory, useParams } from "react-router-dom";

import {
  DotsVerticalIcon,
  ExclamationCircleIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import { GET_PO_ORDERS } from "../../queries";
import { useQuery } from "../../hooks/useQuery";
import { AuthContext } from "../../contexts/auth";
import { AppStateContext } from "../../contexts/appState";
import CustomNotification from "../commons/CustomNotification";
import currencyToSymbolMap from "currency-symbol-map/map";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";
import NewModal from "../commons/NewModal";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";
import PriceBreakUpTooltip from "./PriceBreakUpTooltip";
import CustomPopover from "../commons/CustomPopover";
import { Tooltip, Dropdown } from "antd";
import SlideOverPanel from "../../components/common/SlideOverPanel";
import PurchaseOrderMain from "./PurchaseOrderMain";
import PrimaryButton from "../commons/PrimaryButton";
import AuditTimeline from "./AuditTimeLine";
import CustomAlert from "#newUiComponents/commons/CustomAlert";
import TextArea from "#newUiComponents/commons/TextArea";
import {
  PURCHASE_ORDER_STATUSES,
  USER_ROLE_CLASSIFICATIONS,
  REQUEST_ACTIONS_ON_PO,
  SEARCH_DEBOUNCE_TIME_IN_MS,
  handleGetNextStatus,
  handleCheckForDateAndFormat,
  handleGetStatusColor,
  CheckEligibilityForEditDeleteAndCancel,
  handleCheckEligibilityForSubmitToVendor,
  handleCheckEligibilityForAcceptThePO,
  handleGetTimeAndDate,
  handleInfoTextForSuggestedChanges,
  getPriority,
  columns,
  columnsForLineItemsModal,
  VENDOR_CLASSIFICATIONS,
  handleCheckEligibilityForReadyToShip,
  handleGetTotalQuantityDetails,
  handleCheckEligibilityForAuditTrail,
  handleformatNumericValue,
} from "./PoUtils";
import { ADD_PURCHASE_ORDER } from "../../mutations";
import { CANCEL_PURCHASE_ORDER } from "../../mutations";
import { GET_WAREHOUSES, GET_VENDORS } from "#queries/index";
import { GET_PRODUCTS } from "../../queries";
import { DELETE_PURCHASE_ORDER } from "../../mutations";
import SuggestedChangesCard from "./SuggestedChangesCard";
import { v4 as uuidv4 } from "uuid";
import EnterReadyToShipDetails from "./EnterReadyToShipDetails";
import CustomProgressBar from "#newUiComponents/commons/CustomProgressBar";

function PurchaseOrderList() {
  const getPoOrdresQuery = useQuery(GET_PO_ORDERS);
  const getProductsQuery = useQuery(GET_PRODUCTS);
  const addOrEditPurchaseOrderQuery = useQuery(ADD_PURCHASE_ORDER);
  const cancelPurchaseOrderQuery = useQuery(CANCEL_PURCHASE_ORDER);
  const deletePurchaseOrderQuery = useQuery(DELETE_PURCHASE_ORDER);
  const vendorsQuery = useQuery(GET_VENDORS);
  const warehousesQuery = useQuery(GET_WAREHOUSES);

  const notify = CustomNotification();
  const auth = useContext(AuthContext);
  const appState = useContext(AppStateContext);
  const history = useHistory();
  const URL_PARAMS = useParams();

  const [poFilters, setPoFilters] = useState({
    currentPage: 1,
    pageSize: 20,
    filters: {
      poIds: [],
      externalPoIds: null,
      vendorIds: [],
      statuses: [],
      keyword: "",
    },
  });

  const [warehouses, setWarehouses] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [posTableData, setPosTableData] = useState(null); // setting posTableData null enables table to show skeleton while loading the data also no results image will not be visible before api hits
  const [loadingPos, setLoadingPos] = useState(false);
  const [showLineItems, setShowLineItems] = useState(false);
  const [showAuditTimeLine, setShowAuditTimeLine] = useState(false);
  const [showSuggestedChanges, setShowSuggestedChanges] = useState(false);
  const [auditsTimeline, setAuditsTimeline] = useState({});
  const [lineItemsOnModal, setLineItemsOnModal] = useState([]);
  const [showPurchaseOrderSlide, setShowPurchaseOrderSlide] = useState(false);
  const [showReadyToShipDetailsScreen, setShowReadyToShipDetailsScreen] =
    useState(false);
  const [suggestedChangesPopupOnClick, setSuggestedChangesPopupOnClick] =
    useState(false);
  const [productForPurchase, setProductForPurchase] = useState([]);
  const [purchaseOrderDetailsToShow, setPurchaseOrderDetailsToShow] = useState(
    {},
  );

  const [posInDescendingOrder, setPosInDescendingOrder] = useState({});

  const [toView, setToView] = useState(true);
  const [skipToEdit, setSkipToEdit] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const purchaseOrderRequests = async (
    po,
    actionType,
    cancellationReason = "",
  ) => {
    const purchaseOrder = {
      poId: po.poId,
      vendorId: po.vendorId || "",
      vendorAddress: po.vendor.address,
      warehouseId: po.warehouse.id || "",
      currency: po.currency,
      priority: po.priority,
      poNotes: po.poNotes,
      expectedShipDate: po.expectedShipDate,
      expectedDeliveryDate: po.expectedDeliveryDate,
      status: po.status,
      additionalCharges: [...po.additionalCharges],
      lineItems: po.lineItems.map((product) => {
        delete product.images;
        const components =
          product.components.length > 0 ? [...product.components] : [];
        if (po.isReadyToShip) {
          let productQuantity = product.shippableQuantity;
          delete product.shippableQuantity;
          return {
            ...product,
            lineItemId: product.lineItemId ?? null,
            quantity: productQuantity || 0,
            components,
          };
        }
        return {
          ...product,
          lineItemId: product.lineItemId ?? null,
          components,
        };
      }),
    };
    switch (actionType) {
      case REQUEST_ACTIONS_ON_PO.SHIP_PO:
        const shipPoResponse = await addOrEditPurchaseOrderQuery.fetchData({
          purchaseOrderInput: {
            ...purchaseOrder,
            // specifically for ready to ship request
            carrier: po.carrier,
            trackingNumbers: po.trackingNumbers,
            customerId: po.clientId,
            status: handleGetNextStatus(
              po.status,
              userInfo,
              toView === false,
              false,
            ),
          },
        });
        if (shipPoResponse.data?.handlePoOps) {
          notify.success(
            shipPoResponse.data.handlePoOps.message,
            `POId - ${po.poId}`,
          );
          setShowPurchaseOrderSlide(false);
          history.push("/purchaseOrders");
          setSkipToEdit(false);
          setToView(true);
          handleTriggerHandleGetPoOrders();
          setShowReadyToShipDetailsScreen(false);
        } else if (shipPoResponse.error) {
          notify.error(shipPoResponse.error.message);
        }
        break;
      case REQUEST_ACTIONS_ON_PO.CANCEL_PO:
        const cancelResponse = await cancelPurchaseOrderQuery.fetchData({
          poId: po.poId,
          cancellationReason: cancellationReason,
        });
        if (cancelResponse.data?.cancelPurchaseOrder) {
          notify.success(
            cancelResponse.data.cancelPurchaseOrder.message,
            `POId - ${po.poId}`,
          );
          setShowPurchaseOrderSlide(false);
          history.push("/purchaseOrders");
          setSkipToEdit(false);
          setToView(true);
          handleTriggerHandleGetPoOrders();
        } else if (cancelResponse.error) {
          notify.error(cancelResponse.error.message);
        }
        break;
      case REQUEST_ACTIONS_ON_PO.EDIT_STATUS:
        if (
          purchaseOrder.status ==
            PURCHASE_ORDER_STATUSES.PENDING_INTERNAL_APPROVAL &&
          !purchaseOrder.warehouseId
        ) {
          notify.error(
            "Warehouse Required ,Please edit the po and select a warehouse to proceed.",
          );
          return;
        }
        const statusChangeResponse =
          await addOrEditPurchaseOrderQuery.fetchData({
            purchaseOrderInput: {
              ...purchaseOrder,
              status: handleGetNextStatus(
                po.status,
                userInfo,
                toView === false,
                false,
              ),
            },
          });
        if (statusChangeResponse.data?.handlePoOps) {
          notify.success(
            statusChangeResponse.data.handlePoOps.message,
            `POId - ${po.poId}`,
          );
          setShowPurchaseOrderSlide(false);
          history.push("/purchaseOrders");
          setSkipToEdit(false);
          setToView(true);
          handleTriggerHandleGetPoOrders();
        }
        if (statusChangeResponse.error) {
          notify.error(statusChangeResponse.error.message);
        }
        break;
      case REQUEST_ACTIONS_ON_PO.DELETE_PO:
        const deleteResponse = await deletePurchaseOrderQuery.fetchData({
          poId: po.poId,
        });
        if (deleteResponse.data?.deletePurchaseOrder) {
          notify.success(
            deleteResponse.data.deletePurchaseOrder.message,
            `POId - ${po.poId}`,
          );
          setShowPurchaseOrderSlide(false);
          history.push("/purchaseOrders");
          setSkipToEdit(false);
          setToView(true);
          handleTriggerHandleGetPoOrders();
        } else if (deleteResponse.error) {
          notify.error(deleteResponse.error.message);
        }
        break;
      default:
        console.log("unknown action");
        break;
    }
  };

  const handleCancellationConfirmationRequest = (po) => {
    let cancellationReson = "";
    appState.showNewConfirmation(
      "Cancel PO",
      <div>
        <p>
          {" "}
          Are you sure you want to cancel this Purchase Order? Once canceled,
          you won't be able to perform any further operations on it.
        </p>
        <TextArea
          label="Reason"
          placeholder="Enter reason here ..."
          onChange={(e) => {
            cancellationReson = e.target.value;
          }}
          error=""
          infoText="Enter a reason for cancellation"
          parentClasses="mb-4"
          labelClasses="text-base text-gray-500"
          rows={4}
        />
      </div>,
      () => {
        purchaseOrderRequests(
          po,
          REQUEST_ACTIONS_ON_PO.CANCEL_PO,
          cancellationReson,
        );
      },
      appState.hideConfirmation,
    );
  };

  const handleDeleteConfirmationRequest = (po) => {
    appState.showNewConfirmation(
      "Delete PO",
      <p> Are you sure you want to delete this Purchase Order?</p>,
      () => {
        purchaseOrderRequests(po, REQUEST_ACTIONS_ON_PO.DELETE_PO);
      },
      appState.hideConfirmation,
    );
  };

  const handleSubmitToVendorConfirmationRequest = (po) => {
    appState.showNewConfirmation(
      "Submit To Vendor",
      <p>
        {" "}
        {po.suggestedChangesMerged === false
          ? "Are you sure you want to submit this Purchase Order to Vendor for Review, without accepting suggested changes by Vendor?"
          : "Are you sure you want to submit this Purchase Order to Vendor for Review?"}
      </p>,
      () => {
        purchaseOrderRequests(po, REQUEST_ACTIONS_ON_PO.EDIT_STATUS);
      },
      appState.hideConfirmation,
    );
  };

  const handleAcceptThePoConfirmationRequest = (po) => {
    appState.showNewConfirmation(
      "Accept The PO",
      <p>Are you sure you want to accept this Purchase Order?</p>,
      () => {
        purchaseOrderRequests(po, REQUEST_ACTIONS_ON_PO.EDIT_STATUS);
      },
      appState.hideConfirmation,
    );
  };

  const handleGetActionsOnViewPage = (po) => {
    return [
      po.status === PURCHASE_ORDER_STATUSES.DRAFT && po.isEditable
        ? {
            name: "Mark Pending Approval",
            icon: "",
            onClick: () => {
              purchaseOrderRequests(po, REQUEST_ACTIONS_ON_PO.EDIT_STATUS);
            },
          }
        : null,
      handleCheckEligibilityForSubmitToVendor(po, userInfo)
        ? {
            name: "Submit to Vendor",
            icon: "",
            onClick: () => handleSubmitToVendorConfirmationRequest(po),
          }
        : null,
      handleCheckEligibilityForAcceptThePO(po, userInfo)
        ? {
            name: "Accept the PO",
            icon: "",
            onClick: () => handleAcceptThePoConfirmationRequest(po),
          }
        : null,
      CheckEligibilityForEditDeleteAndCancel(po, "cancel", userInfo) &&
      po.isEditable
        ? {
            name: "Cancel PO",
            icon: "",
            onClick: () => handleCancellationConfirmationRequest(po),
          }
        : null,
      CheckEligibilityForEditDeleteAndCancel(po, "delete", userInfo) &&
      po.isEditable
        ? {
            name: "Delete PO",
            icon: "",
            onClick: () => handleDeleteConfirmationRequest(po),
          }
        : null,
      handleCheckEligibilityForReadyToShip(po, userInfo) && po.isEditable
        ? {
            name: "Ready to ship",
            icon: "",
            onClick: () => {
              setShowReadyToShipDetailsScreen(true);
            },
          }
        : null,
      handleCheckEligibilityForAuditTrail(userInfo)
        ? {
            name: "Audit Trail",
            icon: "",
            onClick: () => {
              setShowAuditTimeLine(true);
              setAuditsTimeline({
                audits: [...po.audits].reverse(),
                updatedAt: po.updatedAt,
                status: po.status,
                poId: po.poId,
              });
            },
          }
        : null,
    ].filter(Boolean);
  };

  const handleGetActionsOnTable = (po) => {
    const mainPo = po;
    return [
      {
        key: 1,
        label: "View PO",
        icon: "",
        onClick: () => {
          setSkipToEdit(false);
          history.push(`/purchaseOrders/${po.poId}`);
        },
      },
      CheckEligibilityForEditDeleteAndCancel(po, "edit", userInfo)
        ? {
            key: 2,
            label: "Edit PO",
            icon: "",
            onClick: () => {
              setSkipToEdit(true);
              history.push(`/purchaseOrders/${po.poId}`);
              setToView(false);
            },
          }
        : null,
      CheckEligibilityForEditDeleteAndCancel(mainPo, "cancel", userInfo)
        ? {
            key: 3,
            label: "Cancel PO",
            icon: "",
            onClick: () => {
              handleCancellationConfirmationRequest(po);
            },
          }
        : null,
      CheckEligibilityForEditDeleteAndCancel(po, "delete", userInfo) &&
      po.isEditable
        ? {
            key: 4,
            label: "Delete PO",
            icon: "",
            onClick: () => handleDeleteConfirmationRequest(po),
          }
        : null,
      handleCheckEligibilityForAuditTrail(userInfo)
        ? {
            key: 5,
            label: "Audit Trail",
            icon: "",
            onClick: () => {
              setShowAuditTimeLine(true);
              setAuditsTimeline({
                audits: [...mainPo.audits].reverse(),
                updatedAt: mainPo.updatedAt,
                status: mainPo.status,
                poId: mainPo.poId,
              });
            },
          }
        : null,
    ].filter(Boolean);
  };

  useEffect(() => {
    // very important useEffect to avoid side effects due to productForPurchase
    if (!showPurchaseOrderSlide) {
      setProductForPurchase([]);
      setToView(true);
      setSkipToEdit(false);
      setShowSuggestedChanges(false);
      setSuggestedChangesPopupOnClick(false);
      setShowReadyToShipDetailsScreen(false);
    }
  }, [showPurchaseOrderSlide]);

  const handleSuggestedChangesActionClick = (po) => {
    setPurchaseOrderDetailsToShow({
      ...po,
      suggestedChangesMerged: false,
    });
    setShowPurchaseOrderSlide(true);
  };

  const handleIncomingData = (data) => {
    return data.map((mainPo, index) => {
      // this is to show on table which is latest version of the po
      const po = mainPo.pos[0];
      // if (index === 1 || index === 2 || index === 5) {
      //   po.suggestedChanges = [];
      // }
      const formattedIncomingDataForTable = {
        ...po,
        orderId:
          po.suggestedChanges &&
          po.status === PURCHASE_ORDER_STATUSES.VENDOR_CHANGES_REQUESTED ? (
            <div className="flex h-24 items-center">
              <span
                className="cursor-pointer text-base font-semibold text-primaryAccent"
                onClick={() => {
                  setSkipToEdit(false);
                  history.push(`/purchaseOrders/${po.poId}`);
                }}>
                {po.poId}
              </span>
              <div
                style={{ top: "5.4rem", minWidth: "30%" }}
                className="absolute inset-x-0 mt-2">
                <div className="flex items-center justify-between rounded-md border border-yellow-300 bg-yellow-50 p-1">
                  <div className="flex items-center">
                    <ExclamationIcon className="mr-2 h-5 w-5 text-yellow-400" />
                    <span className="text-sm text-gray-700">
                      {handleInfoTextForSuggestedChanges(userInfo)}
                    </span>
                  </div>
                  <PrimaryButton
                    height="2rem"
                    width="9rem"
                    className="bg-yellow-50 px-5 text-base"
                    onClick={() => {
                      history.push(`/purchaseOrders/${po.poId}`);
                      setSuggestedChangesPopupOnClick(true);
                      setSkipToEdit(false);
                    }}>
                    View Changes
                  </PrimaryButton>
                </div>
              </div>
            </div>
          ) : (
            <span
              className="cursor-pointer text-base font-semibold text-primaryAccent"
              onClick={() => {
                // setPurchaseOrderDetailsToShow(po);
                // setShowPurchaseOrderSlide(true);
                setSkipToEdit(false);
                history.push(`/purchaseOrders/${po.poId}`);
              }}>
              {po.poId}
            </span>
          ),
        orderId_toSort: po.poId,
        vendorName: po.vendorName ?? "",
        vendorName_toSort: po.vendorName,
        warehouse: po.warehouse.name ?? "",
        warehouse_toSort: po.warehouse.name,
        products: (
          <span
            onClick={() => {
              setShowLineItems(true);
              setLineItemsOnModal(handleLineItemsOnModal(po));
            }}
            className="flex cursor-pointer items-center text-primaryAccent">
            {po.lineItems.length > 1
              ? String(po.lineItems.length).padStart(2, "0")
              : po.lineItems.length === 1
                ? po.lineItems[0]["sku"]
                : 0}
          </span>
        ),
        products_toSort: po.lineItems.length,
        status: (
          <div className="flex items-center gap-2">
            <CustomBadge
              bgColor={handleGetStatusColor(po.status).bgColor}
              textColor={handleGetStatusColor(po.status).textColor}
              label={po.status}
            />
            {po.status === PURCHASE_ORDER_STATUSES.CANCELLED &&
              po.cancellationReason && (
                <Tooltip title={po.cancellationReason}>
                  <ExclamationCircleIcon className="h-4 w-4 cursor-pointer text-gray-500" />
                </Tooltip>
              )}
          </div>
        ),
        status_toSort: po.status,
        orderTotal: (
          <PriceBreakUpTooltip
            charges={[
              { name: "Sub Total", amount: getSubTotalAmount(po) },
              ...po.additionalCharges,
            ]}
            currency={currencyToSymbolMap[po.currency] || ""}
            title={getOrderTotalAmount(po)}
          />
        ),
        orderTotal_toSort: !isNaN(Number(getOrderTotalAmount(po)))
          ? Number(getOrderTotalAmount(po))
          : getOrderTotalAmount(po),
        expectedShipDate: handleCheckForDateAndFormat(po.expectedShipDate),
        expectedShipDate_toSort: po.expectedShipDate
          ? new Date(po.expectedShipDate)
          : new Date(0),
        expectedDeliveryDate: handleCheckForDateAndFormat(
          po.expectedDeliveryDate,
        ),
        expectedDeliveryDate_toSort: po.expectedDeliveryDate
          ? new Date(po.expectedDeliveryDate)
          : new Date(0),
        priority: getPriority(po.priority),
        priority_toSort: po.priority,
        notes: <PoNote note={po.poNotes.length ? po.poNotes[0].note : ""} />,
        notes_toSort: po.poNotes.length ? po.poNotes[0].note : "",
        action: (
          <Dropdown
            trigger={["hover"]}
            menu={{
              items: handleGetActionsOnTable(po),
            }}
            overlayClassName="z-5"
            disabled={handleGetActionsOnTable(po).length === 0}
            dropdownRender={({ props }) => {
              return (
                <div
                  style={{ width: "10rem" }}
                  key={uuidv4()}
                  className="rounded-md border bg-white p-2 text-sm text-gray-600 shadow-md">
                  {props.items.map((item) => (
                    <div
                      key={uuidv4()}
                      onClick={item.onClick}
                      className="cursor-pointer p-3 hover:bg-hoverHighlight hover:text-primaryAccent">
                      {item.label}
                    </div>
                  ))}
                </div>
              );
            }}
            placement="topRight"
            arrow>
            <DotsVerticalIcon className="h-6 w-6 cursor-pointer text-gray-400" />
          </Dropdown>
        ),
      };
      if (
        po.status === PURCHASE_ORDER_STATUSES.PARTIALLY_FULFILLED ||
        po.status === PURCHASE_ORDER_STATUSES.FULFILLED
      ) {
        const { totalExpectedQuantity, totalShippedQuantity } =
          handleGetTotalQuantityDetails(po.lineItems, po.shippingDetails);
        formattedIncomingDataForTable.shipped = (
          <div className="flex flex-col items-center gap-2">
            <div>
              <span>{totalShippedQuantity}</span>/
              <span>{totalExpectedQuantity}</span>
            </div>
            <CustomProgressBar
              progress={(totalShippedQuantity / totalExpectedQuantity) * 100}
            />
          </div>
        );
      }
      return formattedIncomingDataForTable;
    });
  };

  const fetchCatalogProduct = async (
    ids,
    successCallBack = () => {},
    errorCallBack = () => {},
  ) => {
    appState.setLoading();
    const productsQueryResponse = await getProductsQuery.fetchData({
      filters: { id: [...ids] },
    });
    appState.removeLoading();
    if (productsQueryResponse?.data?.products?.entities.length) {
      setProductForPurchase(productsQueryResponse?.data?.products.entities);
      successCallBack(productsQueryResponse?.data?.products.entities);
    } else if (productsQueryResponse?.error) {
      errorCallBack(
        productsQueryResponse?.error?.message || "Error fetching products!",
      );
    }
  };

  const handleLineItemsOnModal = (po) => {
    return po.lineItems.map((lineItem, index) => {
      return {
        productInfo: (
          <div className="flex items-center font-inter">
            <img
              src={
                lineItem.images.length > 0
                  ? lineItem.images[0].url
                  : FALLBACK_IMAGE_URL
              }
              alt={lineItem.name}
              className="mr-4 h-16 w-16"
            />
            <div>
              <Tooltip title={lineItem.name}>
                <div
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    wordWrap: "break-word",
                    width: "300px",
                  }}
                  className="max-w-full truncate text-base font-medium">
                  {lineItem.name}
                </div>
              </Tooltip>
              <div className="text-gray-500">SKU: {lineItem.sku}</div>
              <div className="text-gray-500">Variant: {lineItem.variant}</div>
            </div>
          </div>
        ),
        quantity: lineItem.quantity || 0,
        itemTotal:
          `${currencyToSymbolMap[po.currency] || ""} ${handleformatNumericValue(lineItem.unitPrice)}` ||
          "",
      };
    });
  };
  const getOrderTotalAmount = (po) => {
    const lineItemsTotalAmount = po.lineItems.reduce((acc, curr) => {
      return acc + curr.unitPrice * curr.quantity;
    }, 0);
    const additionalCharges = po.additionalCharges.reduce((acc, curr) => {
      return acc + curr.amount;
    }, 0);
    return handleformatNumericValue(lineItemsTotalAmount + additionalCharges);
  };

  const getSubTotalAmount = (po) => {
    const lineItemsTotalAmount = po.lineItems.reduce((acc, curr) => {
      return acc + curr.unitPrice * curr.quantity;
    }, 0);
    return handleformatNumericValue(lineItemsTotalAmount);
  };

  const handleGetSinglePo = async (key) => {
    appState.setLoading();
    const poOrdersResponse = await getPoOrdresQuery.fetchData({
      perPage: 1,
      pageNumber: 1,
      filters: {
        poIds: [],
        externalPoIds: null,
        vendorIds: [],
        statuses: [],
        keyword: key,
      },
      sort: "-updatedAt",
    });
    appState.removeLoading();
    if (poOrdersResponse.data?.purchaseOrders?.data) {
      poOrdersResponse.data?.purchaseOrders?.data.forEach((po) => {
        const descedingOrderOfPosByVersion = handleGetLatestPo(po.pos).map(
          (newPo, index) => {
            if (index === 0) {
              return {
                isEditable: true,
                suggestedChangesMerged: false,
                versionName: `Version ${newPo.poVersion}`,
                ...po,
                ...newPo,
              };
            }
            return {
              versionName: `Version ${newPo.poVersion}`,
              isEditable: false,
              ...po,
              ...newPo,
            };
          },
        );
        const mainPurchaseOrder = descedingOrderOfPosByVersion[0];
        if (
          mainPurchaseOrder.suggestedChanges &&
          mainPurchaseOrder.status ===
            PURCHASE_ORDER_STATUSES.VENDOR_CHANGES_REQUESTED
        ) {
          handleSuggestedChangesActionClick(mainPurchaseOrder);
        }
        setPurchaseOrderDetailsToShow(mainPurchaseOrder);
        setPosInDescendingOrder((prevPos) => {
          return {
            ...prevPos,
            [po.poId]: descedingOrderOfPosByVersion,
          };
        });
      });
      setShowPurchaseOrderSlide(true);
    }
  };

  const handleGetPoOrders = async (
    perPage,
    pageNumber,
    sort = "-updatedAt",
  ) => {
    if (
      auth &&
      auth.user &&
      auth.user.warehousesList &&
      auth.user.customersList
    ) {
      let warehouses = auth.user.warehousesList;
      let customers = auth.user.customersList;

      if (warehouses.length === 0 || customers.length === 0) {
        return notify.error(
          "You don't have necessary permission to execute this action.",
        );
      }
      setLoadingPos(true);
      const poOrdersResponse = await getPoOrdresQuery.fetchData({
        perPage: perPage ? perPage : poFilters.pageSize,
        pageNumber: pageNumber ? pageNumber : poFilters.currentPage,
        filters: poFilters.filters,
        sort,
      });
      setLoadingPos(false);
      if (poOrdersResponse.data?.purchaseOrders?.metaData)
        setMetaData(poOrdersResponse.data.purchaseOrders.metaData);
      if (poOrdersResponse.data?.purchaseOrders?.data) {
        const modifiedPosTableData =
          poOrdersResponse.data?.purchaseOrders?.data.map((po) => {
            const descedingOrderOfPosByVersion = handleGetLatestPo(po.pos).map(
              (newPo, index) => {
                return {
                  versionName: `Version ${newPo.poVersion}`,
                  isEditable: index === 0,
                  ...po,
                  ...newPo,
                };
              },
            );
            setPosInDescendingOrder((prevPos) => {
              return {
                ...prevPos,
                [po.poId]: descedingOrderOfPosByVersion,
              };
            });
            return {
              ...po,
              pos: descedingOrderOfPosByVersion,
            };
          });
        setPosTableData((prevData) => {
          if (prevData) {
            return [...prevData, ...handleIncomingData(modifiedPosTableData)];
          } else return handleIncomingData(modifiedPosTableData);
        });
      }
    }
  };

  const handleCalculateTotalPrice = (
    originalLineItems,
    suggestedLineItems,
    originalCharges,
    suggestedCharges,
  ) => {
    const suggestedLineItemsMap = new Map(
      suggestedLineItems.map((item) => [item.lineItemId, item]),
    );

    const suggestedChargesMap = new Map(
      suggestedCharges.map((item) => [item.name, item]),
    );

    // Calculate the total for line items
    const lineItemsTotal = originalLineItems.reduce((total, item) => {
      const suggestedItem = suggestedLineItemsMap.get(item.lineItemId);

      // Use original values if suggested values are null
      const finalQuantity = suggestedItem?.quantity ?? item.quantity;
      const finalUnitPrice = suggestedItem?.unitPrice ?? item.unitPrice;

      return total + (finalUnitPrice ?? 0) * (finalQuantity ?? 0);
    }, 0);

    const originalChargesTotal = originalCharges.reduce((total, charge) => {
      const suggestedCharge = suggestedChargesMap.get(charge.name);
      const finalCharge = suggestedCharge || charge;
      return total + finalCharge.amount;
    }, 0);

    const additionalSuggestedChargesTotal = suggestedCharges.reduce(
      (total, charge) => {
        if (
          !originalCharges.some(
            (originalCharge) => originalCharge.name === charge.name,
          )
        ) {
          return total + charge.amount;
        }
        return total;
      },
      0,
    );

    const additionalChargesTotal =
      originalChargesTotal + additionalSuggestedChargesTotal;

    return handleformatNumericValue(lineItemsTotal + additionalChargesTotal);
  };

  const handleGetDetectedChanges = (po) => {
    const originalLineItems = po.lineItems || [];
    const suggestedLineItems =
      po.suggestedChanges?.suggestions?.lineItems || [];
    const originalCharges = po.additionalCharges || [];
    const suggestedCharges =
      po.suggestedChanges?.suggestions?.additionalCharges || [];

    const products = originalLineItems.map((item) => {
      const suggestedItem = suggestedLineItems.find(
        (sItem) => sItem.lineItemId === item.lineItemId,
      );

      const quantityChange = {
        originalQuantity: item.quantity,
        suggestedQuantity: suggestedItem?.quantity ?? item.quantity,
      };

      const priceChange = {
        originalPrice: handleformatNumericValue(item?.unitPrice),
        suggestedPrice:
          suggestedItem?.unitPrice !== null
            ? handleformatNumericValue(
                suggestedItem?.unitPrice ?? item?.unitPrice,
              )
            : handleformatNumericValue(item.unitPrice),
      };

      const components = item.components?.map((component, index) => {
        const suggestedComponent = suggestedItem?.components?.[index];
        return {
          name: component.name,
          price: {
            originalPrice: handleformatNumericValue(component.unitPrice),
            suggestedPrice: suggestedComponent
              ? handleformatNumericValue(
                  suggestedComponent.unitPrice ?? component.unitPrice,
                )
              : handleformatNumericValue(component.unitPrice),
          },
          quantity: {
            originalQuantity: component.quantity,
            suggestedQuantity:
              suggestedComponent?.quantity ?? component.quantity,
          },
          uom: {
            originalUom: component.uom,
            suggestedUom: suggestedComponent?.uom ?? component.uom,
          },
        };
      });

      return {
        name: item.name,
        sku: item.sku,
        variant: item.variant || "",
        image: item.images?.[0]?.url || "",
        quantity: quantityChange,
        price: priceChange,
        components,
      };
    });

    // Combine original and suggested charges
    const allCharges = [
      ...originalCharges,
      ...suggestedCharges.filter(
        (sCharge) =>
          !originalCharges.some((oCharge) => oCharge.name === sCharge.name),
      ),
    ];

    const charges = allCharges.map((charge) => {
      const originalCharge = originalCharges.find(
        (oCharge) => oCharge.name === charge.name,
      );
      const suggestedCharge = suggestedCharges.find(
        (sCharge) => sCharge.name === charge.name,
      );

      const originalAmount = originalCharge?.amount ?? 0;
      const suggestedAmount =
        suggestedCharge?.amount ?? originalCharge.amount ?? 0;

      return {
        name: charge.name,
        amount: {
          originalAmount:
            charge.name === "Discount" ? originalAmount * -1 : originalAmount,
          suggestedAmount:
            charge.name === "Discount" ? suggestedAmount * -1 : suggestedAmount,
        },
      };
    });

    const detectedChanges = {
      products: products.filter(
        (product) =>
          product.quantity.suggestedQuantity !==
            product.quantity.originalQuantity ||
          product.price.suggestedPrice !== product.price.originalPrice ||
          product.components?.some(
            (component) =>
              component.price.suggestedPrice !==
                component.price.originalPrice ||
              component.quantity.suggestedQuantity !==
                component.quantity.originalQuantity ||
              component.uom.suggestedUom !== component.uom.originalUom,
          ),
      ),
      charges: charges,
    };

    const generalInfo = {
      originalPoTotalPrice: handleCalculateTotalPrice(
        originalLineItems,
        [],
        originalCharges,
        [],
      ),
      suggestedPoTotalPrice: handleCalculateTotalPrice(
        originalLineItems,
        suggestedLineItems,
        originalCharges,
        suggestedCharges,
      ),
      vendorNotes: po.vendorNote?.note || "",
      originalExpectedShipDate:
        handleCheckForDateAndFormat(po.expectedDeliveryDate) || "-",
      suggestedExpectedShipDate: handleCheckForDateAndFormat(
        po.suggestedChanges?.suggestions?.expectedShipDate ??
          po.expectedDeliveryDate,
      ),
      originalExpectedDeliveryDate:
        handleCheckForDateAndFormat(po.expectedDeliveryDate) || "-",
      suggestedExpectedDeliveryDate: handleCheckForDateAndFormat(
        po.suggestedChanges?.suggestions?.expectedDeliveryDate ??
          po.expectedDeliveryDate,
      ),
    };

    const isChangesDetected =
      detectedChanges.products.length > 0 ||
      detectedChanges.charges.some(
        (charge) =>
          charge.amount.originalAmount !== charge.amount.suggestedAmount,
      ) ||
      generalInfo.originalPoTotalPrice !== generalInfo.suggestedPoTotalPrice ||
      generalInfo.originalExpectedShipDate !==
        generalInfo.suggestedExpectedShipDate ||
      generalInfo.originalExpectedDeliveryDate !==
        generalInfo.suggestedExpectedDeliveryDate;

    return {
      ...detectedChanges,
      generalInfo,
      isChangesDetected,
    };
  };

  const handleMergeChanges = (po) => {
    const suggestions = po.suggestedChanges?.suggestions || {};

    const mergedLineItems = po.lineItems
      .map((item) => {
        const suggestedItem = suggestions.lineItems?.find(
          (sItem) => sItem.lineItemId === item.lineItemId,
        );

        if (suggestedItem && suggestedItem.quantity === 0) {
          return null;
        }

        return {
          ...item,
          quantity: suggestedItem?.quantity ?? item.quantity,
          unitPrice: suggestedItem?.unitPrice ?? item.unitPrice,
          components: item.components.map((component, index) => {
            const suggestedComponent = suggestedItem?.components?.[index];
            return {
              ...component,
              unitPrice: suggestedComponent?.unitPrice ?? component.unitPrice,
              quantity: suggestedComponent?.quantity ?? component.quantity,
              uom: suggestedComponent?.uom ?? component.uom,
            };
          }),
        };
      })
      .filter(Boolean);

    const newSuggestedLineItems =
      suggestions.lineItems?.filter(
        (sItem) =>
          !po.lineItems.some((item) => item.lineItemId === sItem.lineItemId) &&
          sItem.quantity > 0,
      ) || [];

    const finalMergedLineItems = [...mergedLineItems, ...newSuggestedLineItems];

    const mergedCharges = po.additionalCharges
      .map((charge) => {
        const suggestedCharge = suggestions.additionalCharges?.find(
          (sCharge) => sCharge.name === charge.name,
        );

        if (suggestedCharge && suggestedCharge.amount === 0) {
          return null;
        }

        return {
          ...charge,
          amount: suggestedCharge?.amount ?? charge.amount,
        };
      })
      .filter(Boolean);

    const newSuggestedCharges =
      suggestions.additionalCharges?.filter(
        (sCharge) =>
          !po.additionalCharges.some(
            (charge) => charge.name === sCharge.name,
          ) && sCharge.amount > 0,
      ) || [];

    const finalMergedCharges = [...mergedCharges, ...newSuggestedCharges];

    const mergedExpectedShipDate =
      suggestions.expectedShipDate ?? po.expectedShipDate;
    const mergedExpectedDeliveryDate =
      suggestions.expectedDeliveryDate ?? po.expectedDeliveryDate;

    return {
      ...po,
      suggestedChangesMerged: true,
      lineItems: finalMergedLineItems,
      additionalCharges: finalMergedCharges,
      expectedShipDate: mergedExpectedShipDate,
      expectedDeliveryDate: mergedExpectedDeliveryDate,
    };
  };

  const handleGetLatestPo = (pos) => {
    return pos.sort((a, b) => {
      return b.poVersion - a.poVersion;
    });
  };

  // this function is used for triggering the handleGetPoOrders function with pageNumber(default 1)
  const handleTriggerHandleGetPoOrders = (pageNumber = 1) => {
    setPosTableData(null);
    setPoFilters((prevFilters) => ({
      ...prevFilters,
      currentPage: pageNumber,
    }));
  };

  useEffect(() => {
    if (
      Object.keys(purchaseOrderDetailsToShow).length > 0 ||
      (auth && auth.user)
    ) {
      setUserInfo(() => ({
        role: auth.user?.role,
        classification:
          purchaseOrderDetailsToShow.vendorClassification ||
          VENDOR_CLASSIFICATIONS.INTERNAL_VENDOR,
      }));
    }
  }, [purchaseOrderDetailsToShow, auth]);

  useDebouncedEffect(
    () => {
      handleGetPoOrders();
    },
    SEARCH_DEBOUNCE_TIME_IN_MS,
    [poFilters],
  );

  useEffect(() => {
    if (!showPurchaseOrderSlide) {
      setProductForPurchase([]);
    }
  }, [showPurchaseOrderSlide]);

  useEffect(() => {
    if (URL_PARAMS.poId) {
      handleGetSinglePo(URL_PARAMS.poId);
    }
  }, [URL_PARAMS.poId]);

  useEffect(() => {
    (async () => {
      try {
        const warehousesData = await warehousesQuery.fetchData();
        const vendorsData = await vendorsQuery.fetchData({
          filters: {
            classifications: ["Internal", "Third Party"],
          },
        });
        if (warehousesData.data?.warehouses?.entities?.length > 0)
          setWarehouses(warehousesData.data.warehouses.entities);
        if (vendorsData.data?.vendors?.entities?.length > 0)
          setVendors(vendorsData.data.vendors.entities);
      } catch (e) {
        console.log("Error fetching warehouses", e);
      }
    })();
  }, []);

  const sortFunction = (key, direction) => {
    setPosTableData(
      [...posTableData].sort((a, b) => {
        let valA = a[`${key}_toSort`];
        let valB = b[`${key}_toSort`];

        if (typeof valA === "string" && typeof valB === "string") {
          return direction === "ascending"
            ? valA.localeCompare(valB)
            : valB.localeCompare(valA);
        } else if (typeof valA === "number" && typeof valB === "number") {
          return direction === "ascending" ? valA - valB : valB - valA;
        } else {
          return direction === "ascending"
            ? String(valA).localeCompare(String(valB))
            : String(valB).localeCompare(String(valA));
        }
      }),
    );
  };

  return (
    <div className="p-8 font-inter">
      <div className="mb-4 flex items-center justify-between">
        <HeaderWithArrow
          headerTitle={"Purchase Order"}
          description={"Purchase order context goes here."}
          isLearnMore={true}
          isArrow={false}
        />
      </div>
      <div className="h-70v w-full">
        <CustomTable
          columns={columns}
          data={posTableData}
          onSort={sortFunction}
          isSearchable
          isFilters
          isPagination
          isLoadMore
          isInfiniteScroll
          pageSize={poFilters.pageSize}
          currentPage={poFilters.currentPage}
          setCurrentPage={(page) => {
            setPoFilters((prevFilters) => ({
              ...prevFilters,
              currentPage: page,
            }));
          }}
          setPageSize={(pageSize) => {
            setPoFilters((prevFilters) => ({
              ...prevFilters,
              pageSize: pageSize,
            }));
          }}
          loadingData={loadingPos}
          searchTerm={poFilters.filters.keyword}
          totalDataCount={metaData.total || 0}
          onChangeSearchTerm={(searchTerm) => {
            if (poFilters.filters.keyword !== searchTerm) {
              setPosTableData(null);
              setPoFilters((prevFilters) => ({
                ...prevFilters,
                filters: {
                  ...prevFilters.filters,
                  keyword: searchTerm,
                },
                currentPage: 1,
              }));
            } else
              setPoFilters((prevFilters) => ({
                ...prevFilters,
                filters: {
                  ...prevFilters.filters,
                  keyword: searchTerm,
                },
              }));
          }}
        />
      </div>
      <NewModal
        isOpen={showLineItems}
        onClose={() => {
          setShowLineItems(false);
        }}
        title="PO Products"
        subtitle={`Products : ${lineItemsOnModal.length}`}>
        <div>
          <CustomTable
            columns={columnsForLineItemsModal}
            data={lineItemsOnModal}
            isPagination={false}
            isFilters={false}
            isSearchable={false}
          />
        </div>
      </NewModal>
      <NewModal
        isOpen={showAuditTimeLine}
        onClose={() => {
          setShowAuditTimeLine(false);
          setAuditsTimeline({});
        }}
        title="Audit Trail">
        <div>
          <AuditTimeline auditsTimeline={auditsTimeline} />
        </div>
      </NewModal>
      <NewModal
        isOpen={
          showSuggestedChanges &&
          !purchaseOrderDetailsToShow.suggestedChangesMerged
        }
        onClose={() => {
          setShowSuggestedChanges(false);
        }}
        minWidth="60%"
        maxWidth="70%"
        title={
          userInfo.role === USER_ROLE_CLASSIFICATIONS.VENDOR
            ? "Suggested Changes"
            : "Vendor Suggested Changes"
        }
        description="Changes : 13">
        <div>
          <SuggestedChangesCard
            po={purchaseOrderDetailsToShow}
            {...handleGetDetectedChanges(purchaseOrderDetailsToShow)}
          />
          <div className="sticky bottom-0 left-0 flex justify-end gap-4 bg-white p-3">
            {/* <PrimaryButton
              height="3rem"
              width="7rem"
              className="mt-2 text-base font-medium"
              onClick={() => {
                setShowSuggestedChanges(false);
              }}>
              Cancel
            </PrimaryButton> */}
            {userInfo.role !== USER_ROLE_CLASSIFICATIONS.VENDOR && (
              <PrimaryButton
                height="3rem"
                minWidth="7rem"
                maxWidth="15rem"
                variant="primary"
                className="mt-2 text-base font-medium"
                onClick={() => {
                  appState.showNewConfirmation(
                    "Approve Vendor Changes",
                    <div className="flex flex-col gap-3">
                      <p>
                        Are you sure you want to approve the suggested vendor
                        changes?
                      </p>
                      <CustomAlert
                        id="alertForApproveSuggestedChanges"
                        type="info"
                        message="Please note that Vendor can still make changes to the pricing of the PO in later stage."
                        options={{
                          defaultColors: true,
                        }}
                      />
                    </div>,
                    () => {
                      setPurchaseOrderDetailsToShow((prev) => {
                        const purchaseOrderAfterMergingSuggestedChanges =
                          handleMergeChanges(prev);
                        purchaseOrderRequests(
                          purchaseOrderAfterMergingSuggestedChanges,
                          REQUEST_ACTIONS_ON_PO.EDIT_STATUS,
                        );
                        return purchaseOrderAfterMergingSuggestedChanges;
                      });

                      setPosInDescendingOrder((prevPos) => {
                        return {
                          ...prevPos,
                          [purchaseOrderDetailsToShow.poId]: prevPos[
                            purchaseOrderDetailsToShow.poId
                          ].map((el, index) => {
                            if (index === 0) {
                              return handleMergeChanges(el);
                            }
                            return el;
                          }),
                        };
                      });
                      setShowSuggestedChanges(false);
                    },
                    appState.hideNewConfirmation,
                  );
                }}>
                Approve Changes
              </PrimaryButton>
            )}
          </div>
        </div>
      </NewModal>
      <SlideOverPanel
        open={showPurchaseOrderSlide}
        setOpen={(value) => {
          if (!value) {
            history.push("/purchaseOrders");
          }
          setShowPurchaseOrderSlide(value);
        }}
        isCrossIconVisible={false}
        title={
          <div className="flex justify-between p-2">
            <HeaderWithArrow
              headerTitle={
                <div className="flex items-center gap-4">
                  <div>{purchaseOrderDetailsToShow.poId}</div>
                  {!toView && (
                    <CustomBadge
                      textColor={
                        handleGetStatusColor(purchaseOrderDetailsToShow.status)
                          .textColor
                      }
                      label={purchaseOrderDetailsToShow.status}
                      textSize="text-xs"
                      bgColor={
                        handleGetStatusColor(purchaseOrderDetailsToShow.status)
                          .bgColor
                      }
                    />
                  )}
                </div>
              }
              description={`Last updated on : ${purchaseOrderDetailsToShow.updatedAt ? handleGetTimeAndDate(purchaseOrderDetailsToShow.updatedAt) : ""}`}
              arrowAction={() => {
                if (showReadyToShipDetailsScreen) {
                  setToView(true);
                  setShowReadyToShipDetailsScreen(false);
                  return;
                }
                if (!toView && !skipToEdit) {
                  setToView(true);
                } else {
                  setShowPurchaseOrderSlide(false);
                  history.push("/purchaseOrders");
                  setToView(true);
                  setSkipToEdit(false);
                }
              }}
            />
            {toView && !showReadyToShipDetailsScreen && (
              <div className="flex items-start justify-end gap-6">
                {CheckEligibilityForEditDeleteAndCancel(
                  purchaseOrderDetailsToShow,
                  "edit",
                  userInfo,
                ) &&
                  purchaseOrderDetailsToShow.isEditable && (
                    <PrimaryButton
                      height="3rem"
                      width="7rem"
                      className="mt-2 text-base font-medium"
                      onClick={() => {
                        setToView(false);
                      }}>
                      Edit PO
                    </PrimaryButton>
                  )}
                {handleGetActionsOnViewPage(purchaseOrderDetailsToShow).length >
                  0 && (
                  <CustomPopover
                    actions={handleGetActionsOnViewPage(
                      purchaseOrderDetailsToShow,
                    )}
                    defaultClasses=""
                    styles={{ right: "0" }}
                    bgColorVisible={false}
                    triggerElement={(open) => {
                      return (
                        <PrimaryButton
                          height="3rem"
                          width="7rem"
                          variant="primary"
                          className="mt-2 text-base font-medium"
                          onClick={() => {}}>
                          Actions{" "}
                          {open ? (
                            <ChevronUpIcon className="ml-1 h-6 w-6" />
                          ) : (
                            <ChevronDownIcon className="ml-1 h-6 w-6" />
                          )}
                        </PrimaryButton>
                      );
                    }}
                  />
                )}
              </div>
            )}
          </div>
        }
        containerStyle={"max-w-6xl"}>
        <PurchaseOrderMain
          setShowPurchaseOrderSlide={setShowPurchaseOrderSlide}
          setPosInDescendingOrder={setPosInDescendingOrder}
          purchaseOrderDetailsToShow={purchaseOrderDetailsToShow}
          toView={toView}
          fetchCatalogProduct={fetchCatalogProduct}
          productForPurchase={productForPurchase}
          setProductForPurchase={setProductForPurchase}
          setToView={setToView}
          handleTriggerHandleGetPoOrders={handleTriggerHandleGetPoOrders}
          setPurchaseOrderDetailsToShow={setPurchaseOrderDetailsToShow}
          setShowSuggestedChanges={setShowSuggestedChanges}
          posInDescendingOrder={
            posInDescendingOrder[purchaseOrderDetailsToShow.poId]
          }
          suggestedChangesPopupOnClick={suggestedChangesPopupOnClick}
          setSuggestedChangesPopupOnClick={setSuggestedChangesPopupOnClick}
          showReadyToShipDetailsScreen={showReadyToShipDetailsScreen}
          setShowReadyToShipDetailsScreen={setShowReadyToShipDetailsScreen}
          purchaseOrderRequests={purchaseOrderRequests}
          userInfo={userInfo}
          warehouses={warehouses}
          vendors={vendors}
        />
      </SlideOverPanel>
    </div>
  );
}

export default PurchaseOrderList;
